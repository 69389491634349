import React from 'react'
import PropTypes from 'prop-types'

import layoutQuery from "../hooks/use-layout-query"

import '../assets/scss/main.scss'
import Intro from '../components/intro'
import Header from '../components/header'
import Footer from '../components/footer'

const Layout = ({ children, location, pageContext }) => {
  const data = layoutQuery()

  React.useEffect(() => {
    document.documentElement.style.setProperty(`--headerBackground`, pageContext?.header === 'dark' ? '#172966' : 'transparent');
    document.documentElement.style.setProperty(`--mainPaddingMobile`, pageContext?.header === 'dark' ? '72px' : '0');
    document.documentElement.style.setProperty(`--mainPaddingDesktop`, pageContext?.header === 'dark' ? '101px' : '0');
  }, [pageContext])

  return (
    <>
      <Intro location={location} />
      <div className='site-wrapper'>
        <Header {...data.wp.siteOptions.acf} />
        <main>{ children }</main>
        <Footer {...data.wp.siteOptions.acf} />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
