import { useStaticQuery, graphql } from "gatsby"

const useLayoutQuery = () => {
  return useStaticQuery(graphql`
    fragment WpSeo on WpPostTypeSEO {
      metaDesc
      metaKeywords
      metaRobotsNofollow
      opengraphAuthor
      opengraphDescription
      opengraphImage {
        localFile {
          publicURL
        }
      }
      opengraphModifiedTime
      opengraphPublishedTime
      opengraphPublisher
      opengraphSiteName
      opengraphTitle
      opengraphType
      opengraphUrl
      readingTime
      schema {
        articleType
        pageType
        raw
      }
      title
      twitterDescription
      twitterImage {
        localFile {
          publicURL
        }
      }
      twitterTitle
    }
    fragment ArticleCard on WpPost {
      id
      uri
      title
      categories {
        nodes {
          name
          slug
        }
      }
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1000)
            }
          }
        }
      }
    }
    fragment TeamCard on WpTeamMember {
      id
      uri
      title
      acf {
        jobTitle
        profileImage {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 600)
            }
          }
        }
      }
    }
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      wp {
        siteOptions {
          acf {
            mainMenu {
              link {
                title
                url
              }
            }
            bookButton {
              url
              title
            }
            footerText
            footerMenuOne {
              title
              menu {
                link {
                  title
                  url
                }
              }
            }
            footerMenuTwo {
              title
              menu {
                link {
                  title
                  url
                }
              }
            }
            footerMenuThree {
              title
              menu {
                link {
                  title
                  url
                }
              }
            }
            facebook
            linkedin
            instagram
          }
        }
      }
    }
  `)
}

export default useLayoutQuery