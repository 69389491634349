import React, { useEffect, createRef } from 'react';

import lottie from 'lottie-web'

const Intro = () => {
  let animationContainer = createRef()
  const animation = '/animations/logo-animation.json'

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: false,
      autoplay: true,
      path: animation,
      rendererSettings: {
        progressiveLoad: true
      }
    });
    setTimeout(() => {
    	document && document.querySelector('.intro').classList.add('animate')
    }, 4900)
    return () => anim.destroy()
  }, []);

  return (
    <section className='intro' onClick={() => document && document.querySelector('.intro').classList.add('animate')}>
      <div className='intro__lottie' ref={animationContainer} />
    </section>
  )
}

export default Intro