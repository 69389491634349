import React, { Component } from 'react'
import Link from '../utils/link'
import { Instagram, Facebook, LinkedIn, Xero, Myob } from './icons'
import NewsletterForm from './newsletter-form'
import Mfaa from '../assets/images/mfaa.svg'
import Integrity from '../assets/images/integrity.svg'
import Tpb from '../assets/images/tpb.png'

class Footer extends Component {

  render() {

    let { footerText, footerMenuOne, footerMenuTwo, footerMenuThree, facebook, linkedin, instagram } = this.props

    return (
      <footer className='footer'>
        <div className='footer__inner'>
          <div className='footer__top'>
            <div className='footer__text'>
              <p>{footerText}</p>
            </div>
            <div className='footer__menus'>
              <div className='footer__menu'>
                <h4>{footerMenuOne.title}</h4>
                { footerMenuOne?.menu &&
                  <ul>
                    { footerMenuOne.menu.map((el, i) => (
                      <li key={i}>
                        <Link to={el.link.url}>{el.link.title}</Link>
                      </li>
                    ))}
                    <li><Link to='/privacy-policy/'>Privacy Policy</Link></li>
                  </ul>
                }
              </div>
              <div className='footer__menu'>
                <h4>{footerMenuTwo.title}</h4>
                { footerMenuTwo?.menu &&
                  <ul>
                    { footerMenuTwo.menu.map((el, i) => (
                      <li key={i}>
                        <Link to={el.link.url}>{el.link.title}</Link>
                      </li>
                    ))}
                  </ul>
                }
              </div>
              <div className='footer__menu'>
                <h4>{footerMenuThree.title}</h4>
                { footerMenuThree?.menu &&
                  <ul>
                    { footerMenuThree.menu.map((el, i) => (
                      <li key={i}>
                        <Link to={el.link.url}>{el.link.title}</Link>
                      </li>
                    ))}
                  </ul>
                }
              </div>
            </div>
            <div className='footer__subscribe'>
              <div className='footer__logos'>
                <ul>
                  { facebook && <li><Link to={facebook}><Facebook color='#FFFFFF' /></Link></li> }
                  { instagram && <li><Link to={instagram}><Instagram color='#FFFFFF' /></Link></li> }
                  { linkedin && <li><Link to={linkedin}><LinkedIn color='#FFFFFF' /></Link></li> }
                </ul>
                <div>
                  <img src={Tpb} alt='Tpb' />
                  <img src={Mfaa} alt='MFAA' />
                  <Xero />
                  <Myob />
                </div>
              </div>
              <NewsletterForm />
            </div>
          </div>
          <div className='footer__bottom'>
            <div className='footer__links'>
              <ul>
                <li>© Copyright Proacct+ 2024</li>
                <li>Liability limited by a scheme approved under Professional Standards Legislation.</li>
              </ul>
              <ul>
                <li><Link to='https://atollon.com.au'>Design by Atollon</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
