import React, { Component } from 'react'
import Link from '../utils/link'

import { Logo, Facebook, Instagram, LinkedIn } from './icons'

class Header extends Component {

  state = {
    offCanvas: false,
    scrolled: false,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scrollDetect)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollDetect)
  }

  onScroll = (e) => {
    const currentScroll = window.pageYOffset
    if (currentScroll > 0) {
      this.setState({ 
        scrolled: true,
      })
    } else {
      this.setState({ 
        scrolled: false
      })
    }
  }

  scrollDetect = () => {
    window.clearTimeout( this.isScrolling )
    this.isScrolling = setTimeout(this.onScroll, 0);
  }

  _hideOffCanvas = () => {
    this.setState({ offCanvas: false })
  }

  _toggleOffCanvas = () => {
    this.setState({ offCanvas: !this.state.offCanvas })
  }

  render() {

    let { offCanvas, scrolled } = this.state
    let { mainMenu, facebook, instagram, linkedin, bookButton } = this.props

    let props = {
      onClick: this._hideOffCanvas,
      activeClassName: 'active'
    }

    let headerClass = 'header'
    if (scrolled) headerClass += ' header--scrolled'
    if (offCanvas) headerClass += ' header--off-canvas'

    return (
      <>
        <header className={headerClass}>
          <div className='header__inner'>
            <Link to='/' title='Pro Acct +' className='header__logo' {...props}>
              <Logo color={`#F0F0F0`} />
            </Link>
            <nav className='header__nav'>
              { mainMenu &&
                <ul>
                  { mainMenu.map((el, i) => (
                    <li key={i}>
                      <Link to={el.link.url} {...props}>{el.link.title}</Link>
                    </li>
                  ))}
                  { facebook && <li><Link to={facebook}><Facebook color='#FFFFFF' /></Link></li> }
                  { instagram && <li><Link to={instagram}><Instagram color='#FFFFFF' /></Link></li> }
                  { linkedin && <li><Link to={linkedin}><LinkedIn color='#FFFFFF' /></Link></li> }
                  { bookButton && <li><Link className='btn' to={bookButton.url}>{bookButton.title}</Link></li> }
                </ul>
              }
            </nav>
            <button onClick={this._toggleOffCanvas} className={`header__hamburger ${offCanvas ? 'active' : ''}`}>
              <span className='lines' />
            </button>
          </div>
        </header>
        <div className={`off-canvas ${ offCanvas && 'off-canvas--active' }`}>
          <div className='off-canvas__inner'>
            <nav className='off-canvas__nav'>
              { mainMenu &&
                <ul>
                  { mainMenu.map((el, i) => (
                    <li className={el.text === 'Services' ? 'services' : ''} key={i}>
                      <Link to={el.link.url} {...props}>{el.link.title}</Link>
                    </li>
                  ))}
                  { bookButton && <li><Link className='btn' to={bookButton.url}>{bookButton.title}</Link></li> }
                </ul>
              }
            </nav>
          </div>
        </div>
      </>
    )
  }
}

export default Header
